import { useCallback } from 'react'
import { ChainId, Currency, Token } from '@echodex/sdk'
import styled from 'styled-components'
import {
    Button,
    Text,
    ArrowUpIcon,
    Link,
    ConfirmationPendingContent,
    Modal,
    InjectedModalProps,
    ModalProps,
    AutoColumn,
    ColumnCenter,
} from '@echodex/uikit'
import { useTranslation } from '@echodex/localization'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { WrappedTokenInfo } from '@echodex/token-lists'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { getBlockExploreLink, getBlockExploreName } from '../../utils'
import AddToWalletButton, { AddToWalletTextOptions } from '../AddToWallet/AddToWalletButton'

const Wrapper = styled.div`
    width: 100%;
`
const Section = styled(AutoColumn)`
    padding: 24px;
`

const ConfirmedIcon = styled(ColumnCenter)`
    padding: 24px 0;
`

export function TransactionSubmittedContent({
    onDismiss,
    chainId,
    hash,
    currencyToAdd,
}: {
    onDismiss: () => void
    hash: string | undefined
    chainId: ChainId
    currencyToAdd?: Currency | undefined
}) {
    const { t } = useTranslation()

    const token: Token | undefined = wrappedCurrency(currencyToAdd, chainId)

    return (
        <Wrapper>
            <Section>
                <ConfirmedIcon>
                    <ArrowUpIcon strokeWidth={0.5} width="90px" color="primary" />
                </ConfirmedIcon>
                <AutoColumn gap="12px" justify="center">
                    <Text fontSize="20px">{t('Transaction Submitted')}</Text>
                    {chainId && hash && (
                        <Link external small href={getBlockExploreLink(hash, 'transaction', chainId)}>
                            {t('View on %site%', {
                                site: getBlockExploreName(chainId),
                            })}
                        </Link>
                    )}
                    {currencyToAdd && (
                        <AddToWalletButton
                            variant="tertiary"
                            mt="12px"
                            width="fit-content"
                            marginTextBetweenLogo="6px"
                            textOptions={AddToWalletTextOptions.TEXT_WITH_ASSET}
                            tokenAddress={token ? token.address : undefined}
                            tokenSymbol={currencyToAdd.symbol}
                            tokenDecimals={token ? token.decimals : undefined}
                            tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
                        />
                    )}
                    <Button onClick={onDismiss} mt="20px">
                        {t('Close')}
                    </Button>
                </AutoColumn>
            </Section>
        </Wrapper>
    )
}

interface ConfirmationModalProps {
    title: string
    customOnDismiss?: () => void
    hash: string | undefined
    content: () => React.ReactNode
    attemptingTxn: boolean
    pendingText: string
    currencyToAdd?: Currency | undefined
}

const TransactionConfirmationModal: React.FC<
    React.PropsWithChildren<InjectedModalProps & ConfirmationModalProps & ModalProps>
> = ({ title, onDismiss, customOnDismiss, attemptingTxn, hash, pendingText, content, currencyToAdd, ...props }) => {
    const { chainId } = useActiveChainId()

    const handleDismiss = useCallback(() => {
        if (customOnDismiss) {
            customOnDismiss()
        }
        onDismiss?.()
    }, [customOnDismiss, onDismiss])

    if (!chainId) return null

    return (
        <Modal title={title} headerBackground="gradientCardHeader" {...props} onDismiss={handleDismiss}>
            {attemptingTxn ? (
                <ConfirmationPendingContent pendingText={pendingText} />
            ) : hash ? (
                <TransactionSubmittedContent
                    chainId={chainId}
                    hash={hash}
                    onDismiss={handleDismiss}
                    currencyToAdd={currencyToAdd}
                />
            ) : (
                content()
            )}
        </Modal>
    )
}

export default TransactionConfirmationModal
